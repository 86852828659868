import { TagHeaderWithLink } from './TagHeaderWithLink';
import { TagComponent } from './TagComponent';
import React, { useContext } from 'react';
import { CurrentUserContext } from '../CurrentUserContext';
import { useNewContentCounter } from 'app/core/hooks/useNewContentCounter';
import type { Tag } from '@gn/db/client';

const MENU_TAGS_COUNT = 10;

export const MobileTagsList = ({ tags }: { tags: Pick<Tag, 'id' | 'label' | 'slug'>[] }) => {
    const currentUser = useContext(CurrentUserContext);

    const { newContentByTagsIdCounts } = useNewContentCounter(currentUser);

    const takeFirstRequiredAmount = <T,>(array: T[]) => {
        return array.slice(0, MENU_TAGS_COUNT);
    };
    let menuTags = takeFirstRequiredAmount(tags);

    if (currentUser && currentUser.interests?.length) {
        const userTags = currentUser.interests.map(({ tag }) => tag);
        if (currentUser.interests.length > MENU_TAGS_COUNT) {
            menuTags = takeFirstRequiredAmount(userTags);
        } else {
            const difference = MENU_TAGS_COUNT - currentUser.interests.length;
            menuTags = [...userTags, ...tags.slice(0, difference)];
        }
    }

    return (
        <div className="aside-block visible-md">
            <TagHeaderWithLink />
            <div className="tags-list">
                {menuTags.map((tag, index) => (
                    <TagComponent
                        key={`${tag.id}-${index}`}
                        selectedTag={null}
                        tag={tag}
                        prefetch={true}
                        userNewContentTags={newContentByTagsIdCounts}
                    />
                ))}
            </div>
        </div>
    );
};
