import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import { gSP } from 'app/blitz-server';
import { useContext, useState } from 'react';
import { Event, User, Tag } from '@gn/db';
import Layout from 'app/core/layouts/Layout';
import getTrendingContent from '@gn/core/influx/queries/getTrendingContent';
import { getHomePageContentConfig, freeWorkshopsTitle } from 'app/contents/getHomePageContentConfig';
import { removeDuplicates, orderWithPriority } from 'app/contents/utils';
import { CurrentUserContext } from 'app/core/components/CurrentUserContext';
import { UpcomingEvents } from 'app/core/components/EventsList';
import { EventsSwiper } from 'app/core/components/EventsSwiper';
import { LeftMenu, MenuItems } from 'app/core/components/LeftMenu';
import { MobileTagsList } from 'app/core/components/tags-list/MobileTagsList';
import getEvents from 'app/events/queries/getEvents';
import { EventWithBrand, ContentWithEvent, ContentCategory } from '@gn/core/type';
import { getStandardPagePromises, createCanonicalUrl } from 'app/utils';
import { logDebug } from 'integrations/mezmo/logger';
import getContentForPage from 'app/contents/queries/getContentForPage';
import { ContentSliders } from 'app/contents/ContentSliders';
import { SearchBlock } from 'app/core/components/SearchBlock';
import { featuredContentTitle, latestContentTitle } from '../app/contents/contentConfig';
import { shuffle } from '@gn/core/utils';
import Head from 'next/head';
import { LanguageProvider } from 'app/core/context/LanguageContext';
interface HomePageProps {
  user: User;
  events: EventWithBrand[];
  contents: ContentWithEvent[][];
  trendingContent: ContentWithEvent[];
  tags: Tag[];
  pastEvents: Event[];
  idsFound: Array<number>;
}
const Home = ({
  idsFound,
  contents,
  events,
  tags,
  pastEvents,
  trendingContent
}: HomePageProps) => {
  const currentUser = useContext(CurrentUserContext);
  return <>
            <Head>
                <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          image: 'https://gitnation.org/content/images/2024/05/portal-og-1.png',
          url: 'https://gitnation.org/',
          sameAs: ['https://www.linkedin.com/company/gitnation/'],
          logo: 'https://gitnation.com/img/logo-danger.svg',
          name: 'GitNation Foundation',
          description: 'We organize events with a worthy cause in mind — we want the tech community to become a welcoming ' + 'place for pro engineers and enthusiasts alike. We encourage personal growth, provide support in learning, ' + 'and nurture our community’s passion for the craft.',
          email: 'events@gitnation.org',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Keurenplein 41, Unit A7637',
            addressLocality: 'Amsterdam',
            addressCountry: 'NL',
            addressRegion: 'Noord-Holland',
            postalCode: '1069CD'
          },
          vatID: 'NL856244740B01'
        })
      }}></script>
            </Head>
            <div className="wrap__content wrap__content--reserved">
                <div className="w-main">
                    <div className="w-main__home-grid">
                        <div className="upcoming-events">
                            <div className="aside-title color">Upcoming events</div>
                            <EventsSwiper events={events} withMultipassCard brandDomainUrl />
                        </div>
                        <div className="w-main__top d-flex flex-column">
                            <div className="past-events">
                                <div className="aside-title color">Talks from our events</div>
                                <EventsSwiper events={pastEvents} />
                            </div>
                        </div>
                        <div className="wrap__search">
                            <SearchBlock suggesstion />
                        </div>

                        <MobileTagsList tags={tags} />
                        <LanguageProvider>
                            <ContentSliders config={getHomePageContentConfig()} contents={contents} idsFound={idsFound} trendingContent={trendingContent} category={ContentCategory.Talk} upcomingWorkshops={undefined} showLanguageSelector />
                        </LanguageProvider>
                    </div>
                </div>
            </div>

            <LeftMenu tags={tags} selectedMenuItem={MenuItems.Dashboard} />
            <UpcomingEvents events={events} />
        </>;
};
export const getStaticProps = _withSuperJSONProps(gSP(async ({
  ctx
}) => {
  const startTime = Date.now();
  const promises = getStandardPagePromises(ctx);
  const trendingContentPromise = getTrendingContent({
    daysRange: 2,
    count: 10,
    shouldTrimText: true
  });
  const pastEventsPromise = getEvents({
    where: {
      hideFromList: false,
      startDate: {
        lte: new Date()
      }
    },
    skip: undefined,
    take: 12,
    orderBy: {
      startDate: 'desc'
    },
    select: {
      id: true,
      name: true,
      slug: true,
      noExactDate: true,
      brand: {
        select: {
          icon: true
        }
      },
      startDate: true,
      endDate: true
    }
  }, ctx);
  const contentsPromise = getContentForPage(getHomePageContentConfig(), ctx);
  const [pastEventsResponse, contents, trendingContent, {
    events
  }, {
    tags
  }] = await Promise.all([pastEventsPromise, contentsPromise, trendingContentPromise, ...promises]);
  logDebug({
    message: `Next - getStaticProps /index`,
    url: undefined,
    serverduration: Date.now() - startTime
  });
  const preparedContent = contents.map(contentsArray => {
    if (contentsArray.title === freeWorkshopsTitle) {
      return contentsArray.contents;
    }
    const shuffled = shuffle(contentsArray.contents);
    if (contentsArray.title === latestContentTitle) {
      return orderWithPriority(shuffled);
    }
    if (contentsArray.title === featuredContentTitle) {
      return shuffled.filter(content => !trendingContent.some(trending => trending.id === content.id));
    }
    return shuffled;
  });
  const removeDuplicatesResult = removeDuplicates(preparedContent);
  return {
    props: {
      events,
      contents: removeDuplicatesResult.contents,
      tags,
      pastEvents: pastEventsResponse.events,
      idsFound: removeDuplicatesResult.idsFound,
      trendingContent
    },
    revalidate: 86400
  };
}), []);
Home.getLayout = page => {
  const {
    props: {
      tags
    }
  } = page;
  const canonicalUrl = createCanonicalUrl('');
  return <Layout selectedMenuItem={MenuItems.Dashboard} title="JavaScript talks & workshops by core teams and top engineers" tags={tags} canonicalUrl={canonicalUrl}>
            {page}
        </Layout>;
};
export default _withSuperJSONPage(Home);